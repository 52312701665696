import { Chip, Grid, Typography } from "@mui/material";
import { technologies } from "../data/about";
import { primaryColor, secondaryColor } from "../data/color";

const Skills = () => {
  return (
    <Grid container spacing={2} p={4}>
      <Grid item xs={12}>
        <Typography variant="h3">Skills</Typography>
      </Grid>
      {technologies.map((tech, index) => (
        <Grid item key={index}>
          <Chip
            size="medium"
            label={tech}
            variant="outlined"
            color="primary"
            sx={{
              "&:hover": {
                backgroundColor: primaryColor,
                color: "white",
              },
              fontWeight: "bold",
              color: "black",
              borderColor: primaryColor,
              borderStyle: "none",
              backgroundColor: secondaryColor,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Skills;
