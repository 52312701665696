export const galleryImages = [
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231046/Gallery/atq1levagehvmki200ua.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231046/Gallery/atq1levagehvmki200ua.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231046/Gallery/wb78phfxrwm1e2mfllby.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231046/Gallery/wb78phfxrwm1e2mfllby.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231046/Gallery/qk2n2dafnkruallyrpy6.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231046/Gallery/qk2n2dafnkruallyrpy6.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231045/Gallery/kgk0506w6pqi62dxgela.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231045/Gallery/kgk0506w6pqi62dxgela.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231045/Gallery/hnwl8vsrjgq7tglck6oo.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231045/Gallery/hnwl8vsrjgq7tglck6oo.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231044/Gallery/flaxoxyaozdqlq8vqyhq.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231044/Gallery/flaxoxyaozdqlq8vqyhq.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231044/Gallery/d6trcvft1khkmzxrk0if.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231044/Gallery/d6trcvft1khkmzxrk0if.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231043/Gallery/us5tbdkv4ct41gnyxk0h.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231043/Gallery/us5tbdkv4ct41gnyxk0h.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231042/Gallery/sgnr03lyjpeviu6vrggs.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231042/Gallery/sgnr03lyjpeviu6vrggs.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231042/Gallery/ijgjcig9vwl7xmkvos8h.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231042/Gallery/ijgjcig9vwl7xmkvos8h.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231041/Gallery/tgay7zvc3m4ubfoaz9iu.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231041/Gallery/tgay7zvc3m4ubfoaz9iu.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231041/Gallery/scrxkrim49dt1zclnio6.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231041/Gallery/scrxkrim49dt1zclnio6.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/dpivhvaymj3psh2emcls.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/dpivhvaymj3psh2emcls.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/iyynf9qgmeux29i27uia.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/iyynf9qgmeux29i27uia.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/lsym7snqaq83o75lhwyl.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/lsym7snqaq83o75lhwyl.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/rxddg8gal79stjh6ooza.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/rxddg8gal79stjh6ooza.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/ou8ifo22f8keowcdwtbt.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231039/Gallery/ou8ifo22f8keowcdwtbt.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231038/Gallery/kbyxt7thyhg1pffg6k99.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231038/Gallery/kbyxt7thyhg1pffg6k99.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231038/Gallery/xebcuyi74fkcr2iyznwq.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231038/Gallery/xebcuyi74fkcr2iyznwq.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231038/Gallery/z3t2jacodxv2x3sabsjt.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231038/Gallery/z3t2jacodxv2x3sabsjt.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/bqiadlw02xwc90akbhjf.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/bqiadlw02xwc90akbhjf.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/fsuf5hples2ai9hncibb.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/fsuf5hples2ai9hncibb.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/cr0on1a9rwdh0icjcwuf.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/cr0on1a9rwdh0icjcwuf.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/kwuciejnivj3rwloof5l.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/kwuciejnivj3rwloof5l.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/qs0dobqnlrh1oalmvijj.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/qs0dobqnlrh1oalmvijj.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/vfr1ez289luhu1vdwub8.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/vfr1ez289luhu1vdwub8.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/c8w81xwnyzloxdd0nhkc.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231037/Gallery/c8w81xwnyzloxdd0nhkc.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/dflklnofljepmoslaiiu.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/dflklnofljepmoslaiiu.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/ojuoyo8bg6viushwaxhp.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/ojuoyo8bg6viushwaxhp.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/hfzrjxc5bctklanajb00.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/hfzrjxc5bctklanajb00.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/hnnu866y3qnjg5zpmler.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/hnnu866y3qnjg5zpmler.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/vldou9xmfwvmfwqvjokm.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/vldou9xmfwvmfwqvjokm.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231035/Gallery/tscmjthbnbdcb7nwhc5e.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231035/Gallery/tscmjthbnbdcb7nwhc5e.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/tnjjazd1nwai9kkgwa1c.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231036/Gallery/tnjjazd1nwai9kkgwa1c.jpg",
  },
  {
    original:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231035/Gallery/bpmfwfdqrhtyhvy5s8px.jpg",
    thumbnail:
      "http://res.cloudinary.com/betterjs/image/upload/v1717231035/Gallery/bpmfwfdqrhtyhvy5s8px.jpg",
  },
];
