import { Box, Button, Card, Grid, Typography } from "@mui/material";
import ProjectCard from "../components/ProjectCard";
import { projects } from "../data/project";
import { primaryColor } from "../data/color";
import LaunchIcon from "@mui/icons-material/Launch";

const Projects = () => {
  return (
    <Box id="Projects" mt={1}>
      <Grid container spacing={2} p={4}>
        <Grid item xs={12}>
          <Typography variant="h3">Projects</Typography>
        </Grid>
        {projects.map(
          (
            {
              name,
              description,
              skills,
              imageUrl,
              codeLink,
              demoLink,
              completed,
            }: any,
            index: number
          ) => (
            <Grid item xs={12} key={index}>
              <ProjectCard
                name={name}
                description={description}
                skills={skills}
                imageUrl={imageUrl}
                demoLink={demoLink}
                codeLink={codeLink}
                completed={completed}
                mb={2}
              />
            </Grid>
          )
        )}

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            fullWidth
            sx={{
              backgroundColor: "#fff",
              color: "black",
              "&:hover": {
                backgroundColor: primaryColor,
                color: "white",
              },
            }}
            size="small"
            href={"https://github.com/abhijeetsatpute"}
            target="_blank"
            rel="noopener"
            // variant="contained"
            endIcon={<LaunchIcon />}
          >
            More
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Projects;
