import * as React from "react";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { primaryColor, secondaryColor } from "../data/color";
import LaunchIcon from "@mui/icons-material/Launch";
import CodeIcon from "@mui/icons-material/Code";
import { defaultProjectImage } from "../data/project";

const ProjectCard = ({
  name,
  description,
  skills,
  imageUrl,
  codeLink,
  demoLink,
  completed,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: "1px solid #E2DFD2",
      }}
    >
      {!isMobile && (
        <Box sx={{ position: "relative", width: 300, minWidth: 200 }}>
          <CardMedia
            component="img"
            image={imageUrl ?? defaultProjectImage}
            alt={name}
            sx={{
              width: "100%",
              objectFit: "contain",
              padding: "5px",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              backgroundColor: "black",
              color: "white",
              textAlign: "center",
              padding: "2px 0",
            }}
          >
            {completed ? "Completed" : "In Progress"}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "10px",
        }}
      >
        {isMobile && (
          <Box sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              image={imageUrl ?? defaultProjectImage}
              alt={name}
              sx={{
                height: 250,
                minHeight: 250,
                objectFit: "contain",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                padding: "5px 0",
              }}
            >
              {completed ? "Completed" : "In Progress"}
            </Box>
          </Box>
        )}
        <Box>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            fontWeight={600}
            fontFamily={"Tilt Warp"}
          >
            {name}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {description}
          </Typography>
          <Box mt={1} sx={{ display: "flex", flexWrap: "wrap" }}>
            {skills.map((skill: any, index: any) => (
              <Chip
                key={index}
                size="medium"
                label={skill}
                variant="outlined"
                color="primary"
                sx={{
                  mr: 1,
                  mb: 1,
                  "&:hover": {
                    backgroundColor: primaryColor,
                    color: "white",
                  },
                  fontWeight: "bold",
                  color: "black",
                  borderColor: primaryColor,
                  borderStyle: "none",
                  backgroundColor: secondaryColor,
                }}
              />
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginRight: "20px",
            width: "100%",
          }}
        >
          {codeLink && (
            <Button
              sx={{
                backgroundColor: primaryColor,
                color: "white",
                "&:hover": {
                  backgroundColor: "#BDB76B",
                  color: "black",
                },
                mr: 1,
              }}
              size="small"
              href={codeLink}
              target="_blank"
              rel="noopener"
              variant="contained"
              endIcon={<CodeIcon />}
              disabled={!completed}
            >
              View Code
            </Button>
          )}
          {demoLink && (
            <Button
              sx={{
                backgroundColor: primaryColor,
                color: "white",
                "&:hover": {
                  backgroundColor: "#BDB76B",
                  color: "black",
                },
              }}
              size="small"
              href={demoLink}
              target="_blank"
              rel="noopener"
              variant="contained"
              endIcon={<LaunchIcon />}
              disabled={!completed}
            >
              Demo
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectCard;
