import { ThemeProvider } from "@mui/material/styles";
import "./app.css";
import About from "./sections/About";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";
import Header from "./sections/Header";
import Projects from "./sections/Projects";
import theme from "./theme";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Gallery from "./components/Gallery";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Galleryv2 from "./components/Galleryv2";

function App() {
  const pageTheme = useTheme();
  const isMobile = useMediaQuery(pageTheme.breakpoints.down("md"));
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Box
                    width={isMobile ? "100%" : "50%"}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // align children in the center horizontally
                      margin: "auto", // center the Box horizontally
                    }}
                  >
                    <About />
                    <Projects />
                    <Contact />
                  </Box>
                  <Footer />
                </>
              }
            />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
