export const about_para1 = `
Hi 👋, I'm <b>Abhijeet</b>, 
A Fullstack developer based in India with 3+ years of experience in creating dynamic, high-performance web applications using the MERN stack and a variety of JavaScript technologies. 
<br/>
<br/>
💻 My primary focus is on backend development, leveraging tools like Node.js, Express.js, and Nest.js to build robust, scalable systems. I also enjoy working with React.js, PostgreSQL, MongoDB, Docker, and AWS services to deliver full-fledged solutions.
`;

export const about_para2 = `
🚀 I believe in a product-based approach to building things, prioritizing simple, effective solutions over unnecessarily complex frameworks. 
Sometimes, the world needs straightforward answers that just work. 
<br/>
<br/>
Amid my coding and work, I also indulge in photography [📸<a style="text-decoration: none !important;color:black; font-weight: bold" href="/gallery">clicks</a>] and a bit of gaming [🎮<a style="text-decoration: none !important; color:black; font-weight: bold" target="_blank" href="https://steamcommunity.com/profiles/76561198880811638/">Steam</a>]
<br/>
<br/>
💡 I'm passionate about continuous learning and always eager to take on new challenges in the tech world!
`;

export const technologies = [
  "JavaScript",
  "TypeScript",
  "Node.js",
  "Nest.js",
  "React.js",
  "GraphQL",
  "PostgreSQL",
  "MongoDB",
  "Serverless",
  "Microservices",
  "BullMQ",
  "Git",
  "Docker",
  "Kubernetes",
  "AWS",
  "Jenkins",
  "Github Workflows",
  "Figma",
];
