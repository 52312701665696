import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useNavigate } from "react-router-dom";
import { Box, Button, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { galleryImages } from "../data/gallery";

const Gallery = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    // Set loading to false once the images have loaded
    setLoading(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div style={{ position: "relative" }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/")}
        sx={{
          position: isMobile ? "relative" : "absolute",
          top: isMobile ? 0 : 16,
          left: isMobile ? 0 : 16,
          zIndex: 99,
        }}
      >
        Back
      </Button>
      {loading && (
        <Box mt={isMobile ? 20 : 0}>
          <Skeleton
            variant="rounded"
            width="100%"
            height={isMobile ? 200 : 600} // Adjust the height based on your design requirements
            style={{
              // position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
            }}
          />
        </Box>
      )}
      {
        <Box mt={isMobile ? 10 : 0}>
          <ImageGallery items={galleryImages} onImageLoad={handleImageLoad} />
        </Box>
      }
    </div>
  );
};

export default Gallery;
