export const projects = [
  {
    name: "IPO Signal",
    description:
      "A Platform that lets user subscribe to the latest IPO reminders along with their GMP",
    skills: ["Node.js", "React.js", "PostgreSQL", "Puppeteer"],
    // imageUrl:
    // "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1",
    codeLink:
      "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1",
    demoLink:
      "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1",
    completed: false,
  },
  {
    name: "Temp Mail",
    description: "A Platfrom that gives you a temporary email to use.",
    skills: ["Node.js"],
    // imageUrl:
    // "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1",
    codeLink:
      "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1",
    demoLink:
      "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1",
    completed: false,
  },
  {
    name: "Jobs board",
    description: "A Platfrom that helps you track all your job applications.",
    skills: ["Node.js"],
    // imageUrl:
    // "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1",
    codeLink:
      "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1",
    demoLink:
      "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1",
    completed: false,
  },
];

export const defaultProjectImage =
  "https://cdn-icons-png.freepik.com/512/2166/2166823.png?filename=backend_2166823.png&fd=1";
