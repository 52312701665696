import { technologies } from "./about";

export const experience = [
  {
    date: "Sept 2022 - Present",
    designation: "Software Development Engineer",
    company: "AFour Technologies Pvt Ltd, Pune IN",
    content: `
            <ul>
            <li><strong>Full stack developer</strong>, Transforming Age </li>

            <li><strong>Node.js Backend Developer</strong>, RMS internal tool</li>
            </ul>
    `,
    technologies: [
      "Node.js",
      "Nest.js",
      "React.js",
      "JavasScript",
      "TypeScript",
      "PostgreSQL",
      "Jest",
      "AWS",
    ],
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUD9XmP9jLZisHHjDFP7djsfi57y2W8DkSQQ&s",
  },
  {
    date: "Mar 2021 - Sept 2022",
    designation: "Assistant System Engineer",
    company: "Tata Consultancy Services (TCS), Mumbai IN",
    content: `
            <ul>
            <li><strong>UI Developer</strong>, Westpac Banking Corp. Websites Improvement BAU</li>

            <li><strong>Node.js Developer</strong>, Westpac Banking Corp. API Migration</li>
            </ul>
    `,
    technologies: [
      "Node.js",
      "Swagger",
      "Bulma",
      "GEL",
      "NVDA",
      "JavasScript",
      "HTML5",
      "CSS3",
      "jQuery",
    ],
    logo: "https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/uploadedManual-65aa12064b4e0_tata-consultancy-services-squarelogo-1634801936555__1_.png?d=200x200",
  },
  {
    date: "Jan 2021 - Mar 2021",
    designation: "Associate Software Engineer Intern",
    company: "Tech Mahindra, Nagpur IN",
    content: `
            <ul>
            <li>Created various Apache Nifi Dataflows to: convert file formats, Ingesting/Fetching files to/from ADLS gen2, Merge data of
            input files, Inserting MysqlDB from Adls gen2 Files</li>

            <li>Azure Data Factory : Historical data load from CSV files, Incremental data load from single CSV file.</li>
            </ul>
    `,
    technologies: ["Azure"],
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxj_YS7I2p1Gc27NfeBkdOnlcuW4sl_4VrzQ&s",
  },
  {
    date: "Aug 2016 - Dec 2020",
    designation: "Bachelor of Engineering in Computer Technology",
    company: "Nagpur University",
    content: "CGPA: 8.33/10",
    technologies: [],
    logo: "https://iras-proxy-assets.s3.ap-south-1.amazonaws.com/606d7b01ea27269c10306f0f/logo/4-9-2021--6-06-37-am-logo.png",
  },
];
