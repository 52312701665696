import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    h3: {
      fontFamily: "Tilt Warp",
    },
  },
});

export default theme;
